import { SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { DisplayedTabName, HiddenTabName, ItemMenuType } from '@ptg-shared/layout/constance/layout.const';
import { SidebarOpenedStatus } from '@ptg-shared/layout/constance/layout.const';
import { FundType } from '@ptg-shared/types/enums';

// export type TabName = 'admin' | 'participants' | 'individualParticipant' | 'searchedParticipant' | 'processing' | 'app';

export type TabName = DisplayedTabName | HiddenTabName;

export interface TabsUrl {
  participants: string;
  admin: string;
  processing: string;
  individualParticipant: string;
  searchedParticipant: string;
  app: string;
}

export interface MenuItem {
  name: string;
  routerLink: string;
  routerLinkExpand?: string[];
  queryParams?: any;
  id?: string;
  className?: string | string[];
  style?: string;
  type?: ItemMenuType;
  fundType?: number | number[];
  versionFlag?: string | string[];
  viewFixType?: ViewFixType;
  isOverView?: boolean;
  cardId?: string;
  isDetailView?: boolean;
  permissionKey?: string;
}

export interface Menu {
  id?: string;
  title?: string;
  style?: string;
  type?: ItemMenuType;
  menuItems?: MenuItem[];
  icon?: string;
  versionFlag?: string | string[];
  isSingleView?: boolean;
  fundType?: FundType;
  isDetailView?: boolean;
}

export interface MenuGroup {
  name: string;
  icon: string;
  menu?: Menu[];
  isSVG?: boolean;
  isSelected?: boolean;
  permissionKey?: string;
  routerLink?: string;
  isHidden?: boolean;
  moduleKey: SUBMODULE_KEY;
  versionFlag?: string | string[];
}

export enum ViewFixType {
  ParticipantRelationships,
  PaymentInformation,
  StatusHistory,
  ServiceHistory,
  Payments,
  AddressHistory,
  Beneficiary,
  Dependent
}

export interface PaymentInfoParams {
  isHistory: boolean;
  paymentSourceId: string;
  payeeId: string;
  benefitId: string;
}

// export interface ListMenuItem {
//   type: string;
//   menuItems: MenuItem[];
// }

// export interface MenuItem {
//   title: string;
//   style?: string;
//   subItems: SubMenuItem[];
// }

// export interface SubMenuItem {
//   name: string;
//   routerLink: string;
//   type?: ItemMenuType;
//   queryParams?: any;
// }

export interface Loading {
  showLoading: boolean;
}

export interface EmployerDetail {
  id: string,
  clientId: string,
  code: string,
  name: string,
  disabled: boolean,
  type: number,
}

