import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import { VersionFlag } from '@ptg-shared/constance/common.const';
import { EmployerSubmoduleKey } from '@ptg-shared/constance/employer-portal-permission.const';
import { PERMISSION_KEY, SUBMODULE_KEY } from '@ptg-shared/constance/permission.const';
import { DisplayedTabName, MUNICIPALITY_PARTICIPANTS_MENU_TITLE } from '@ptg-shared/layout/constance/layout.const';
import { Menu, MenuGroup } from '@ptg-shared/layout/models/layout.model';
import { FundType } from '@ptg-shared/types/enums';


// PARTICIPANT MENU
export const PORTAL_MEMBER_DOCUMENT_LIST: Menu[] = [
  {
    title: 'Documents',
    menuItems: [
      {
        name: 'Document List',
        routerLink: '/member/document-list',
        routerLinkExpand: ['/document-list'],
      },
      {
        name: 'Generated Document',
        routerLink: '/member/generated-document',
        routerLinkExpand: ['/member/generated-document'],
        versionFlag: VersionFlag.BVFF_Part_2,
      },
    ],
  },
  {
    title: 'Letter',
    menuItems: [
      {
        name: 'Age 65 Letter',
        routerLink: '/member/generate-age65-letter',
        routerLinkExpand: ['/member/generate-age65-letter'],
      },
    ],
  },
];

export const PORTAL_MEMBER_RETIREMENT_BENEFITS: Menu[] = [
  {
    title: 'Accident Processing',
    menuItems: [
      {
        name: 'Accident',
        routerLink: '/member/accident/',
        routerLinkExpand: ['/member/accident/'],
        fundType: FundType.BVFF,
      },
    ],
  },
  // @-> Temporary commented these menus for not using at this moment
  // {
  //   title: 'Purchase Services',
  //   menuItems: [
  //     {
  //       name: 'Buyback/Make-up',
  //       routerLink: `/member/buyback-makeup-overview/`,
  //       routerLinkExpand: [`/member/buyback-makeup-overview/`],
  //     },
  //   ],
  //   fundType: FundType.BVFF,
  // },
  // {
  //   title: 'Returns Processing',
  //   menuItems: [
  //     {
  //       name: 'Refunds',
  //       routerLink: `/member/refunds-overview/`,
  //       routerLinkExpand: [`/member/refunds-overview/`],
  //     },
  //   ],
  //   fundType: FundType.BVFF,
  // },
];

export const PORTAL_MEMBER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [{ name: 'Overview', routerLink: '/member/detail/' }]
  }
];

// EMPLOYER MENU
export const PORTAL_BVFF_EMPLOYER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [{ name: 'Profile', routerLink: '/employer/detail' }],
  },
  {
    title: MUNICIPALITY_PARTICIPANTS_MENU_TITLE,
    menuItems: [
      {
        name: 'All Participants',
        routerLink: '/member',
        queryParams: {},
      },
      {
        name: 'Active Participants',
        routerLink: '/member',
        queryParams: { status: 'Active' },
      },
      {
        name: 'Inactive Participants',
        routerLink: '/member',
        queryParams: { status: 'Inactive' },
      },
    ],
  },
  {
    title: 'Annual Reporting',
    menuItems: [
      {
        name: 'Annual Certification',
        routerLink: '/employer/annual-certification-history',
        routerLinkExpand: ['/employer/annual-certification/'],
      },
      {
        name: 'Remittance',
        routerLink: '/employer/remittance-submission-history',
        routerLinkExpand: ['/employer/remittance-submission-history/'],
        permissionKey: EmployerSubmoduleKey.Remittance,
      },
      {
        name: 'Transactions',
        routerLink: '/employer/transactions',
        routerLinkExpand: ['/employer/transactions/'],
      },
    ],
  },
  {
    title: 'Documents',
    menuItems: [
      {
        name: 'Document List',
        routerLink: '/employer/individual-document',
        routerLinkExpand: ['employer/individual-document'],
      },
    ],
  },

];

export const PORTAL_BVFF_EMPLOYER_SETTING_MENU: Menu[] = [
  {
    title: 'Settings',
    menuItems: [{ name: 'Fee Amounts', routerLink: '/employer/fee-amounts' }],
  },
];

export const PORTAL_BVFF_EMPLOYER_OVERVIEW_REPORT_PENSION_MENU: Menu[] = [
  {
    title: 'Pension Reports',
    menuItems: [
      {
        name: 'Service List',
        routerLink: '/employer/report-service-list',
      },
    ],
  },
  {
    title: 'Letters',
    menuItems: [
      {
        name: 'Annual Fee Letter',
        routerLink: '/employer/annual-fee-letter',
      },
      {
        name: 'Late Fee Reminder',
        routerLink: '/employer/late-fee-reminder-letter',
      },
    ],
  },
];

export const PORTAL_EMPLOYER_DOCUMENT_LIST: Menu[] = [
  {
    title: 'All Documents',
    menuItems: [
      {
        name: 'All Document List',
        routerLink: '/employer/document-list',
        routerLinkExpand: ['/document-list'],
      },
      // FIXME apply to 96391 only
      // {
      //   name: 'Generated Document',
      //   routerLink: '/employer/generated-document',
      //   routerLinkExpand: ['/member/generated-document'],
      //   versionFlag: VersionFlag.Release_2,
      // },
    ],
  },
];

export const PORTAL_EMPLOYER_OVERVIEW_MENU_LIST: Menu[] = [
  {
    title: '',
    menuItems: [
      { name: 'Overview', routerLink: '/employer/detail' },
      { name: 'Departments', routerLink: '/employer/department-management' },
      {
        name: 'Wage Processing',
        routerLink: '/employer/wage-processing',
      },
      {
        name: 'Document List',
        routerLink: '/employer/individual-document',
        routerLinkExpand: ['employer/individual-document'],
      },
    ],
  },
  {
    title: 'Employer Settings',
    icon: 'tune',
    style: 'padding-top: 16px; border-top: 1px solid #124462; margin-right: 15px;',
    menuItems: [
      {
        name: 'Parameters',
        routerLink: '/employer/parameters',
      },
      {
        name: 'Reporting Calendar',
        routerLink: '/employer/reporting-calendar',
      },
    ],
  },
];

export const PORTAL_BVFF_EMPLOYER_REPORT_MENU: Menu[] = [
  {
    title: 'Reports',
    id: 'reports',
    menuItems: [
      { name: 'Transactions', routerLink: '/employer/report-transactions' },
      { name: 'Annual Certification', routerLink: '/employer/report/annual-certification' },
    ],
  },
  {
    title: 'Relief Reports',
    id: 'relief-reports',
    menuItems: [{ name: 'Disbursement Summary', routerLink: '/employer/report-disbursement-summary' }],
  },
  {
    title: 'Pension Reports',
    id: 'pension-reports',
    menuItems: [],
  },
  {
    title: 'Letters',
    menuItems: [
      {
        name: 'Annual Fee Letter',
        routerLink: '/employer/annual-fee-letter',
      },
      {
        name: 'Late Fee Reminder',
        routerLink: '/employer/late-fee-reminder-letter',
      },
    ],
  },
];

// FOR PARTICIPANT MENU GROUP
export const PORTAL_MEMBER_DOCUMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.DOCUMENTS,
  name: 'document-list',
  icon: 'document_scanner',
  menu: PORTAL_MEMBER_DOCUMENT_LIST,
  permissionKey: PERMISSION_KEY.DOCUMENTS,
};

export const PORTAL_MEMBER_RETIREMENT_BENEFIT_OVERVIEW_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'retirement-benefit',
  icon: 'calculate',
  menu: PORTAL_MEMBER_RETIREMENT_BENEFITS,
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
};

export const PORTAL_MEMBER_MANAGEMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'member_list',
  icon: 'list',
  permissionKey: PERMISSION_KEY.PARTICIPANTS,
  routerLink: '/member'
};

export const PORTAL_MEMBER_PROFILE_OVERVIEW_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.PARTICIPANT_LIST,
  name: 'demographics',
  icon: 'account_circle',
  menu: PORTAL_MEMBER_OVERVIEW_MENU_LIST,
  permissionKey: PERMISSION_KEY.PARTICIPANTS
};

export const PORTAL_INDIVIDUAL_DOCUMENT_MENU: Menu[] = [
  {
    title: 'Documents',
    menuItems: [
      {
        name: 'Document List',
        routerLink: '/member/individual-document',
        routerLinkExpand: ['member/individual-document'],
      },
    ],
  },
];

export const PORTAL_MEMBER_INDIVIDUAL_DOCUMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.DOCUMENTS,
  name: 'individual_document',
  icon: 'document_scanner',
  menu: PORTAL_INDIVIDUAL_DOCUMENT_MENU,
  permissionKey: EmployerSubmoduleKey.ParticipantOverview,
};

// FOR EMPLOYER MENU GROUP
export const PORTAL_BVFF_EMPLOYERS_REPORT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: 'employer_report',
  icon: 'stacked_bar_chart',
  permissionKey: EmployerSubmoduleKey.EmployerOverview,
  menu: PORTAL_BVFF_EMPLOYER_OVERVIEW_REPORT_PENSION_MENU,
};

export const PORTAL_BVFF_EMPLOYERS_SETTING_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'tune',
  permissionKey: EmployerSubmoduleKey.EmployerOverview,
  menu: PORTAL_BVFF_EMPLOYER_SETTING_MENU,
};

export const PORTAL_EMPLOYERS_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'apartment',
  routerLink: '/employer/employer-list',
  permissionKey: PERMISSION_KEY.EMPLOYERS
};

export const PORTAL_EMPLOYER_DOCUMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_DOCUMENT,
  name: 'document-list',
  icon: 'document_scanner',
  menu: PORTAL_EMPLOYER_DOCUMENT_LIST,
  permissionKey: SUBMODULE_KEY.EMPLOYER_DOCUMENT,
};

export const TOLERANCE_MENU: Menu[] = [
  {
    title: 'Settings',
    menuItems: [
      {
        name: 'Tolerance Parameter',
        routerLink: '/employer/tolerance/parameters',
      },
      {
        name: 'Contribution Interest Rate',
        routerLink: '/employer/contribution-interest-rate',
      },
    ],
  },
];

export const TOLERANCE_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.EMPLOYER_LIST,
  name: '',
  icon: 'tune',
  permissionKey: PERMISSION_KEY.EMPLOYER_LIST,
  menu: TOLERANCE_MENU,
};

export const EMPLOYER_DOCUMENT_LIST: Menu[] = [
  {
    title: 'All Documents',
    menuItems: [
      {
        name: 'All Document List',
        routerLink: '/employer/document-list',
        routerLinkExpand: ['/document-list'],
      },
    ],
  },
];

export const EMPLOYER_DOCUMENT_MENU_GROUP: MenuGroup = {
  moduleKey: SUBMODULE_KEY.DOCUMENTS,
  name: 'document-list',
  icon: 'document_scanner',
  menu: EMPLOYER_DOCUMENT_LIST,
  permissionKey: PERMISSION_KEY.DOCUMENTS,
};

export const PORTAL_PARTICIPANTS_MENU_GROUPS = [PORTAL_MEMBER_MANAGEMENT_MENU_GROUP, PORTAL_MEMBER_DOCUMENT_MENU_GROUP];

export const PORTAL_PARTICIPANT_OVERVIEW_MENU_GROUPS = [
  PORTAL_MEMBER_PROFILE_OVERVIEW_MENU_GROUP,
  PORTAL_MEMBER_RETIREMENT_BENEFIT_OVERVIEW_MENU_GROUP,
  PORTAL_MEMBER_INDIVIDUAL_DOCUMENT_MENU_GROUP,
];
export const PORTAL_EMPLOYERS_MENU_GROUPS = [PORTAL_EMPLOYERS_MENU_GROUP, TOLERANCE_MENU_GROUP, PORTAL_EMPLOYER_DOCUMENT_MENU_GROUP];

export const DEFAULT_EMPLOYER_PORTAL_TAB_MENU_GROUPS: Partial<Record<DisplayedTabName, MenuGroup[]>> = {
  employers: PORTAL_EMPLOYERS_MENU_GROUPS,
  participants: PORTAL_PARTICIPANTS_MENU_GROUPS,
  individualParticipant: PORTAL_PARTICIPANT_OVERVIEW_MENU_GROUPS,
  searchedParticipant: PORTAL_PARTICIPANT_OVERVIEW_MENU_GROUPS,
};
