import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SortType } from '@ptg-shared/constance/value.const';
import { DeductionList } from '@ptg-processing/features/payroll/services/models';
import { environment } from 'src/environments/environment';

import {
  IssueOffCyclePaymentRequest,
  MemberAddressItems,
  NextPaymentData,
  OffCyclesPayments,
  PaymentDeduction,
  PaymentDeductionQuery,
  PaymentHistoryListResponse,
  PaymentHistoryQuery,
  PaymentInforConfig,
  PaymentNotesQuery,
  PendingPaymentListResponse,
  PendingPaymentQuery,
  RecalculateTotalDeductionsData,
  SetOffCyclePaymentPayPeriodRequest,
} from '../types/models';

@Injectable({
  providedIn: 'root',
})
export class NextPaymentService {
  memberId?: string = '';
  payeeId?: string = '';
  payrollId?: string = '';

  constructor(private httpClient: HttpClient) {}

  getNextPayment(
    section: number,
    isHistory: boolean = false,
    targetId?: string,
    benefitId?: string
  ): Observable<NextPaymentData> {
    let params = new HttpParams();
    params = params.append('Type', section);
    params = params.append('BenefitId', benefitId || '');
    if (isHistory) {
      if (this.payeeId && this.payrollId) {
        params = params.append('PayeeId', this.payeeId);
        params = params.append('PayrollId', this.payrollId);
      }
      return this.httpClient.get<NextPaymentData>(
        `${environment.apiUrl}/Payroll/PaymentInformation/PaymentHistory/${
          targetId || this.memberId
        }/Details`,
        { params }
      );
    }
    return this.httpClient.get<NextPaymentData>(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${
        targetId || this.memberId
      }/Details`,
      { params }
    );
  }

  getNextPaymentHistory(
    query: PaymentHistoryQuery,
    memberId: string
  ): Observable<PaymentHistoryListResponse> {
    let params = new HttpParams();
    if (query.totalPerPage) {
      params = params.append('PageSize', query.totalPerPage);
    }
    params = params.append('PageIndex', query.pageIndex);

    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }

    return this.httpClient.get<PaymentHistoryListResponse>(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${memberId}/PaymentHistories`,
      { params }
    );
  }

  addNote(body: any, targetId?: string) {
    let payload = { ...body };
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/NextPayroll/PaymentInfo/${
        targetId || this.memberId
      }/Notes`,
      payload
    );
  }

  updateEarnings(body: any, targetId?: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${
        targetId || this.memberId
      }/EarningsBuckets`,
      body
    );
  }

  recalculateTotalDeductions(body: RecalculateTotalDeductionsData) {
    return this.httpClient.post(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${this.memberId}/RecalculateTotalDeductions`,
      body
    );
  }

  getFederalTables() {
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${this.memberId}/PayrollSettings/FederalTables`
    );
  }

  updatePayrollSettings(body: any, targetId?: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${
        targetId || this.memberId
      }/PayrollSettings`,
      body
    );
  }

  updatePayment(memberId: string, body: any, targetId?: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${
        targetId || memberId
      }/Payments`,
      body
    );
  }

  updateDeduction(body: any, targetId?: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${
        targetId || this.memberId
      }/Deductions`,
      body
    );
  }

  getMemberAddressItems(): Observable<MemberAddressItems> {
    return this.httpClient.get<MemberAddressItems>(
      `${environment.apiUrl}/Members/${this.memberId}/MemberAddressItems`
    );
  }

  updateMemo(body: any) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/NextPayroll/PaymentInfo/Notes`,
      body
    );
  }

  removeMemo(id: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Payroll/NextPayroll/PaymentInfo/Notes/${id}`
    );
  }

  getNotes(query: PaymentNotesQuery, targetId?: string) {
    let params = new HttpParams();
    if (query.paymentSourceId) {
      params = params.append('PaymentSourceId', query.paymentSourceId);
    }
    params = params.append('PageSize', query.totalPerPage);
    params = params.append('PageIndex', query.pageIndex);
    params = params.append('SortNames', 'PostedDate');
    params = params.append('SortType', 1);
    return this.httpClient.get(
      `${environment.apiUrl}/Payroll/NextPayroll/PaymentInfo/${
        targetId || this.memberId
      }/Notes`,
      { params }
    );
  }

  getRepresentativePayees() {
    return this.httpClient.get(
      `${environment.apiUrl}/Members/${this.memberId}/RepresentativePayees`
    );
  }

  getPendingPayments(
    query: PendingPaymentQuery
  ): Observable<PendingPaymentListResponse> {
    let params = new HttpParams();
    if (query.totalPerPage && query.pageIndex) {
      params = params.append('PageSize', query.totalPerPage);
      params = params.append('PageIndex', query.pageIndex);
    }
    if (query.sortField) {
      params = params.append(
        'SortNames',
        query.sortField[0].toUpperCase() + query.sortField.substring(1)
      );
      params = params.append('SortType', query.sortType ? query.sortType : 0);
    }
    return this.httpClient.get<PendingPaymentListResponse>(
      `${environment.apiUrl}/Payroll/NextPayroll/Participants/${query.participantId}/PendingPayments`,
      { params }
    );
  }

  issueOffCyclePayment(body: IssueOffCyclePaymentRequest) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/OffCycles/Payments/IssueOffCyclePayment`,
      body
    );
  }

  getPaymentDeductions(
    query: PaymentDeductionQuery,
    clientId: string
  ): Observable<{ total: number; deductions: PaymentDeduction[] }> {
    let params = new HttpParams();
    params = params.append('ClientId', clientId);
    if (query.sortField) {
      params = params.append('SortNames', query.sortField);
      params = params.append('SortType', query.sortType || 0);
    }
    return this.httpClient.get<DeductionList>(
      `${environment.apiUrl}/Deductions`,
      { params }
    );
  }

  createOffCyclePayment(body: OffCyclesPayments): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.apiUrl}/Payroll/OffCycles/Payments`,
      body
    );
  }

  withdrawOffCyclePayment(offCyclePaymentId: string) {
    return this.httpClient.put(
      `${environment.apiUrl}/Payroll/OffCycles/Payments/${offCyclePaymentId}/WithdrawOffCyclePayment`,
      {}
    );
  }

  removeOffCyclePayment(offCyclePaymentId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Payroll/OffCycles/Payments`,
      { body: { offCyclePaymentId } }
    );
  }

  getPaymentInforConfig() {
    return this.httpClient.get(
      `${environment.apiUrl}/PaymentInfor/Configuration`
    );
  }

  savePaymentInforConfig(paymentInforConfig: PaymentInforConfig[]) {
    let body = {
      configurations: paymentInforConfig,
    };
    return this.httpClient.put(
      `${environment.apiUrl}/PaymentInfor/Configuration`,
      body
    );
  }

  setOffCyclePaymentPayPeriod(
    id: string,
    body: SetOffCyclePaymentPayPeriodRequest
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/Payroll/OffCyclePayments/${id}/PayPeriod`,
      body
    );
  }
}
