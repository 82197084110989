<div class="add-employer-contact-container" id="add-employer-contact-dialog">
  <ptg-title-bar
    [name]="data?.contact ? 'Edit' : 'Add New'"
    [viewName]="data?.configSection?.displayName ?? ''"
    [onlyTitle]="true"
  ></ptg-title-bar>

  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="form-control-container">
      <ptg-checkbox
        [controlField]="editForm.get('isPrimary')"
        label="Primary Contact"
      ></ptg-checkbox>
      <div class="group-control group-5">        
        <ptg-select
          placeholder="Prefix"
          [controlField]="editForm.get('name')?.get('prefix')"
          [listData]="listPrefix"
          [hasNoneValue]="true"
          [isOptionObj]="true"
        ></ptg-select>
        <ptg-textbox
          [controlField]="editForm.get('name')?.get('first')"
          placeholder="First Name"
          [hasLabel]="true"
          [isRequired]="true"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="editForm.get('name')?.get('middle')"
          placeholder="Middle Name"
          [hasLabel]="true"
        ></ptg-textbox>        
        <ptg-textbox
          [controlField]="editForm.get('name')?.get('last')"
          placeholder="Last Name"
          [hasLabel]="true"
          [isRequired]="true"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="editForm.get('name')?.get('suffix')"
          placeholder="Suffix"
          [hasLabel]="true"
        ></ptg-textbox>
      </div>
      <ptg-textbox
        [controlField]="editForm.get('phone')"
        placeholder="Phone (Cell)"
        [hasLabel]="true"
        [maxLength]="30"
        [isRequired]="true"
        errorPattern="Invalid Phone (Cell) format."
        [inputType]="'Phone'">
      ></ptg-textbox>
      <ptg-textbox
        [controlField]="editForm.get('email')"
        placeholder="Email"
        [hasLabel]="true"
        [isRequired]="true"
        errorPattern="Invalid Email format."
        [inputType]="'Email'"
      ></ptg-textbox>
      <div class="group-control group-2">
        <ptg-textbox
          [controlField]="editForm.get('address')?.get('street1')"
          placeholder="Street 1"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="46"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="editForm.get('address')?.get('street2')"
          placeholder="Street 2 (Apartment No./Suite No.)"
          [hasLabel]="true"
          [maxLength]="46"
        ></ptg-textbox>
      </div>
      <div class="group-control group-4">
        <ptg-textbox
          [controlField]="editForm.get('address')?.get('city')"
          placeholder="City"
          [hasLabel]="true"
          [isRequired]="true"
          [maxLength]="46"
        ></ptg-textbox>
        <ptg-select
          placeholder="State/Province/Territory"
          [controlField]="editForm.get('address')?.get('stateCode')"
          [listData]="listState"
          [isOptionObj]="true"
          [isRequired]="true"
        ></ptg-select>
        <ng-container *ngIf="editForm.get('address')?.get('countryCode')?.value === UnitedStates">
          <ptg-textbox
            [controlField]="editForm.get('address')?.get('zipCode')"
            placeholder="Zip Code/Postal Code"
            [hasLabel]="true"
            [maxLength]="10"
            [isRequired]="true"
            inputType="ZipCode"
          ></ptg-textbox>
        </ng-container>
        <ng-container *ngIf="editForm.get('address')?.get('countryCode')?.value !== UnitedStates">
          <ptg-textbox
            [controlField]="editForm.get('address')?.get('zipCode')"
            placeholder="Zip Code/Postal Code"
            [hasLabel]="true"
            [maxLength]="10"
            [isRequired]="true"
          ></ptg-textbox>
        </ng-container>
        <ptg-select
          placeholder="Country"
          [controlField]="editForm.get('address')?.get('countryCode')"
          [listData]="listCountry"
          [isOptionObj]="true"
          [isRequired]="true"
        ></ptg-select>
      </div>
    </div>
  </form>
</div>
