<ptg-breadcrumb
  [listBreadcrumbs]="breadcrumbs"
  ></ptg-breadcrumb>
<div *ngIf="!isEntityView; else entityView">
  <ptg-overview-header
    *ngIf="isEstablishBenefit"
  ></ptg-overview-header>  
</div>
<ng-template #entityView>
    <ptg-overview-header-entity [targetIdFromDialog]="targetId"></ptg-overview-header-entity>
</ng-template>
<ptg-progress-spinner *ngIf="isLoading"></ptg-progress-spinner>
<div class="edit-form" *ngIf="!isLoading">
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="dialog-header">
      <button
        mat-raised-button
        type="submit"
        style="background-color: #408bf9; color: #fff"
      >
        Save
      </button>
      <button
        mat-raised-button
        type="button"
        style="background-color: #acabab; color: #fff"
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
    <div class="form-content">
      <div class="flex gap-5">
        <div class="flex-grow flex flex-col gap-5">
          <ptg-textbox
            *ngFor="let bucket of buckets"
            [controlField]="formGroup.get(bucket.bucketName)"
            [placeholder]="bucket.bucketName"
            [hasLabel]="true"
            inputType="Currency"
            (onBlur)="onBlurEarningInput()"
            [min]="0.0"
            [max]="maxValueNumber"
            [errorMinMaxValue]="
              bucket.bucketName +
              ' must be within the range of 0.00 - 9,999,999,999,999.99.'
            "
            customError="crossEarningsValidate"
          ></ptg-textbox>
        </div>

        <div style="width: 50px"></div>

        <div class="flex-grow flex flex-col gap-5">
          <ptg-toggle-button
            *ngIf="!hiddenFederal"
            label="Recalculate Federal Tax"
            [controlField]="recalculateFederalTaxCtrl"
            [disabled]="disabledFederal"
            (valueChange)="onChangeRecalculateToggle()"
          ></ptg-toggle-button>
          <ptg-toggle-button
            *ngIf="!hiddenState"
            label="Recalculate State Tax"
            [controlField]="recalculateStateTaxCtrl"
            [disabled]="disabledState"
            (valueChange)="onChangeRecalculateToggle()"
          ></ptg-toggle-button>

          <div class="currency-label">
            <label>Gross Payment</label>
            <div>
              <span class="dollar-sign">$</span>
              <span>{{ grossPayment | numberDecimal : { decimal: 2 } }}</span>
            </div>
          </div>
          <div class="currency-label">
            <label>Total Deductions</label>
            <div>
              <span class="dollar-sign">$</span>
              <span>{{ totalDeductions | numberDecimal : { decimal: 2 } }}</span>
            </div>
          </div>
          <div class="currency-label">
            <label>Net Payment</label>
            <div>
              <span class="dollar-sign">$</span>
              <span>{{ netPayment | numberDecimal : { decimal: 2 } }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
